import React from 'react';

const MintCard = ({ title = null, children }) => {
  return (
    <div className=" bg-white rounded-2xl p-4 sm:p-6 mt-2">
      {title && <div className="mb-2 text-2xl font-bold text-center">{title}</div>}
      {children}
    </div>
  );
};

export default MintCard;