import React from 'react';

import MintLayout from '@/sections/mint/MintLayout';
import MintCard from '@/sections/mint/MintCard';
import GoToPatrol from '@/sections/mint/GoToPatrol';

const MintActivateSuccess = () => {
  return (
    <MintLayout>
      <div className="min-h-screen">
        <MintCard title="Congratulations">
          <div className='text-center'>
            <div className='font-medium'>Your token is activated successfully</div>
          </div>
        </MintCard>

        <div className='mt-2'>
          <GoToPatrol />
        </div>
      </div>
    </MintLayout>
  );
};

export default MintActivateSuccess;